import React, { useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { IconButton, Box, Typography, Alert, Button } from '@mui/material'
import { Add as AddIcon, Download as DownloadIcon } from '@mui/icons-material'
import { PATHS } from '../../constants'
import * as XLSX from 'xlsx'
import iconv from 'iconv-lite'
import {
    validateKanjiHiraganaKatakana,
    validateHiraganaKatakana,
    validateEmail,
    validatePassword,
    validatePhone,
    validateNumbers
} from '../../Utilities/fieldValidators'
import UserSearch from './UserSearch'
import { isMobile } from '../../Utilities/isMobile'

const AdminUserManagements = ({ history }) => {
    const [newEmployments, setNewEmployments] = useState([])
    const [uploadError, setUploadError] = useState('')
    const [message, setMessage] = useState('')

    const mustField = [
        'name_first',
        'name_last',
        'email',
        'password',
        'phone',
        'emp_code',
        'role',
        'e_type',
        'open_close_duty',
        'seg_code',
        'is_manager'
    ]
    const validEType = ['part-time', 'full-time', 'foreigner']
    const validRole = ['hall', 'kitchen', 'both']
    const yesno = ['YES', 'NO']
    const uploadFalied = 'XLSXファイルをアップロードできませんでした。'
    const hasError = (key, value) => {
        if (
            (mustField.includes(key) && !value) ||
            ((key === 'name_first' || key === 'name_last') && !validateHiraganaKatakana(value)) ||
            ((key === 'kanji_last' || key === 'kanji_first') && value && !validateKanjiHiraganaKatakana(value)) ||
            (key === 'email' && !validateEmail(value)) ||
            (key === 'password' && !validatePassword(value)) ||
            (key === 'phone' && !validatePhone(String(value))) ||
            (key === 'emp_code' && !validateNumbers(String(value))) ||
            (key === 'role' && !validRole.includes(value)) ||
            (key === 'e_type' && !validEType.includes(value)) ||
            (key === 'open_close_duty' && !yesno.includes(value)) ||
            (key === 'seg_code' && !validateNumbers(String(value))) ||
            (key === 'is_manager' && !yesno.includes(value)) ||
            (key === 'name_middle' && value && !validateHiraganaKatakana(value))
        ) {
            return true
        }
        return false
    }
    const handleErrorMessage = (field, line, message = '', currError = '') => {
        let errorMessage = currError || uploadFalied
        const fieldJapanese = {
            name_first: '名(ひらがな/カタカナ)',
            name_last: '姓(ひらがな/カタカナ)',
            name_middle: 'ミドルネーム',
            kanji_first: '名(漢字/ひらがな/カタカナ)',
            kanji_last: '姓(漢字/ひらがな/カタカナ)',
            email: 'メールアドレス',
            password: '初期パスワード',
            phone: '電話番号',
            emp_code: '従業員ID',
            role: '役割',
            e_type: '雇用形態',
            open_close_duty: '開閉担当',
            seg_code: '部署コード',
            is_manager: 'マネージャー'
        }
        if (message) {
            errorMessage += message
        } else {
            errorMessage += `${line}行目の${fieldJapanese[field]}が正しくありません。`
        }
        return errorMessage
    }

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles[0].name?.split('.')[1] !== 'xlsx') {
            setUploadError(uploadFalied + 'ファイルの拡張子が正しくありません。')
            return
        }
        const f = acceptedFiles[0]
        const reader = new FileReader()
        reader.onload = async evt => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            // Get first worksheet
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            // Convert array of arrays
            let data = XLSX.utils.sheet_to_json(ws, { header: 1 })
            data = data.filter(item => {
                return item.length > 0
            })
            let shiftjis = false
            data[0].forEach(col => {
                if (col.includes('')) {
                    shiftjis = true
                }
            })
            // decodes with iconv and reparses if garbled characters found in first row
            if (shiftjis) {
                const decoded = iconv.decode(bstr, 'shiftjis')
                const wb = XLSX.read(decoded, { type: 'binary' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                data = XLSX.utils.sheet_to_json(ws, { header: 1 })
                data = data.filter(item => {
                    return item.length > 0
                })
            }
            // error handling
            const emps = {}
            const email = {}
            const keys = data[1]
            let segCodeList = []
            let empCodeList = []
            let emailList = []
            let errorMessage = ''
            const segCodeIndex = keys.indexOf('seg_code')
            const empIndex = keys.indexOf('emp_code')
            const emailIndex = keys.indexOf('email')
            // check if file is too large
            if (data.length > 10000) {
                setUploadError(
                    uploadFalied + 'ファイルが大きすぎます。10,000行以下のファイルをアップロードしてください。'
                )
                return
            }
            // check if all required fields are present
            const allFields = mustField.every(field => {
                return keys.includes(field)
            })
            if (!allFields) {
                setUploadError(uploadFalied + 'ファイルのフォーマットが正しくありません。もう一度確認してください。')
                return
            }
            // check for duplicate emp codes and emails
            for (let i = 2; i < data.length; i++) {
                let duplicateEmailLine = false
                let duplicateEmpCdLine = false
                emps[data[i][empIndex]] ? (duplicateEmpCdLine = emps[data[i][empIndex]]) : (emps[data[i][empIndex]] = i)
                email[data[i][emailIndex]]
                    ? (duplicateEmailLine = email[data[i][emailIndex]])
                    : (email[data[i][emailIndex]] = i)
                if (duplicateEmailLine || duplicateEmpCdLine) {
                    const duplicateEmpText = `従業員ID: ${i + 1}行目と${duplicateEmpCdLine + 1} 行目 「${
                        data[i][empIndex]
                    }」`
                    const duplicateEmailText = `メールアドレス: ${i + 1}行目と${duplicateEmailLine + 1}行目 「${
                        data[i][emailIndex] + 1
                    }」`
                    setUploadError(
                        `${
                            uploadFalied +
                            '以下の箇所が重複しています。' +
                            (duplicateEmpCdLine ? duplicateEmpText : '') +
                            '\n' +
                            (duplicateEmailLine ? duplicateEmailText : '')
                        }`
                    )
                    return
                }
                if (!segCodeList.includes(data[i][segCodeIndex])) {
                    segCodeList.push(data[i][segCodeIndex])
                }
                if (!empCodeList.includes(data[i][empIndex])) {
                    empCodeList.push(data[i][empIndex])
                }
                if (!emailList.includes(data[i][emailIndex])) {
                    emailList.push(data[i][emailIndex])
                }
                // check if required fields are filled and if fields are valid
                keys.forEach((key, j) => {
                    if (hasError(keys[j], data[i][j])) {
                        errorMessage = handleErrorMessage(keys[j], i + 1, '', errorMessage)
                    }
                })
            }
            if (errorMessage) {
                setUploadError(errorMessage)
                return
            }
            let noStores = true
            await AxiosWithAuth.get('/stores/presence/' + `${segCodeList.join(',')}`).then(res => {
                if (res?.data?.message !== 'All stores found') {
                    setUploadError(uploadFalied + '入力された部署コードが存在しません。')
                    noStores = false
                }
            })
            let noUsers = true
            await AxiosWithAuth.post('/users/presence', {
                emp_cd: empCodeList,
                email: emailList
            }).then(res => {
                if (res?.data?.message === 'User found') {
                    setUploadError(uploadFalied + '入力されたユーザーが既に存在します。')
                    noUsers = false
                }
            })
            if (!noUsers || !noStores) {
                return
            }
            setNewEmployments(data)
        }
        reader.readAsBinaryString(f)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const handleSampleDownload = () => {
        const japaneseHeader = [
            '部署コード(半角数字のみ)',
            '姓(ひらがな/カタカナ)',
            'ミドルネーム(ひらがな/カタカナ)',
            '名(ひらがな/カタカナ)',
            '姓(漢字/ひらがな/カタカナ)',
            '名(漢字/ひらがな/カタカナ)',
            'メールアドレス(半角英数字)',
            '電話番号(ハイフンを除く半角数字)',
            '従業員ID(半角数字)',
            '勤務タイプ(hall/kitchen/both)',
            'ランク(A/B/T)',
            '雇用形態(part-time/full-time/foreigner)',
            'マネジャー(YES/NO)',
            '開閉担当(YES/NO)',
            '初期パスワード(8文字以上•大文字•数字を含む)'
        ]
        const englishHeader = [
            'seg_code',
            'name_last',
            'name_middle',
            'name_first',
            'kanji_last',
            'kanji_first',
            'email',
            'phone',
            'emp_code',
            'role',
            'rank',
            'e_type',
            'is_manager',
            'open_close_duty',
            'password'
        ]
        const wb = XLSX.utils.book_new()
        const data = [[...japaneseHeader], [...englishHeader]]
        const ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws)
        XLSX.writeFile(wb, 'ユーザーリスト＿サンプル.xlsx')
    }
    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }
    }, [message])
    useEffect(() => {
        if (newEmployments.length === 0) return
        history.push({ pathname: PATHS.adminUserBulkUpload, state: { data: newEmployments } })
    }, [newEmployments])

    useEffect(() => {
        if (history?.location?.state?.message) {
            setMessage(history.location.state.message)
            delete history.location.state.message
            history.replace(history.location)
        }
    }, [])

    return (
        <PageWithTitleLayout title="ユーザーの検索と編集">
            <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {history.location.state?.deleted && (
                    <Alert
                        sx={{ width: isMobile() ? '20rem' : '45rem', margin: '1rem' }}
                        severity="success"
                        onClose={() => {
                            delete history.location.state.deleted
                            history.replace(history.location)
                        }}
                    >
                        ユーザー{history.location.state.deleted}
                        を削除しました。
                    </Alert>
                )}
                <UserSearch path={`${PATHS.userManagementStores}admin/noEmployment/`} history={history} />
                {!isMobile() && <div className="title-layout">
                    <div className="title-bar">
                        <Typography variant="subtitle1" margin="1rem" sx={{ width: '45rem' }}>
                            ユーザーの一括登録
                        </Typography>
                    </div>
                    <div
                        style={{
                            margin: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {uploadError && (
                            <Alert
                                severity="error"
                                sx={{ width: '45rem', margin: '1rem' }}
                                onClose={() => setUploadError('')}
                            >
                                {uploadError}
                            </Alert>
                        )}
                        {message && (
                            <Alert
                                severity="success"
                                sx={{ width: '45rem', margin: '1rem', whiteSpace: 'pre-line' }}
                                onClose={() => setMessage('')}
                            >
                                {message}
                            </Alert>
                        )}

                        <Box
                            sx={{
                                p: 2,
                                bgcolor: '#F5F5F5',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gridTemplateColumns: { md: '1fr 1fr' },
                                gap: 2,
                                width: '45rem',
                                height: '10rem',
                                border: '1px dashed #D9D9D9',
                                borderRadius: '2px',
                                color: '#8C8C8C',
                                '&:hover': {
                                    color: 'gray',
                                    backgroundColor: '#D9D9D9'
                                }
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <Box>ファイルをここにドラッグ＆ドロップ</Box>
                        </Box>
                        <div
                            style={{ display: 'flex', justifyContent: 'flex-start', width: '48rem', margin: '.5rem 0' }}
                        >
                            <Button onClick={handleSampleDownload}>
                                <DownloadIcon /> XLSX形式ファイルのサンプルをダウンロードする
                            </Button>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '48rem',
                                marginLeft: '1rem'
                            }}
                        >
                            <Typography color="#424242">
                                ファイルをインポートする前に以下の項目が入力されていることを確認してください。
                                <br /> &nbsp;&nbsp;1. 部署コード(半角数字のみ)
                                <br /> &nbsp;&nbsp;2. 姓(ひらがな/カタカナ)
                                <br /> &nbsp;&nbsp;3. ミドルネーム(ひらがな/カタカナ)
                                <br /> &nbsp;&nbsp;4. 名(ひらがな/カタカナ)
                                <br /> &nbsp;&nbsp;5. 姓(漢字/ひらがな/カタカナ)
                                <br /> &nbsp;&nbsp;6. 名(漢字/ひらがな/カタカナ)
                                <br /> &nbsp;&nbsp;7. メールアドレス(半角英数字)
                                <br /> &nbsp;&nbsp;8. 電話番号(ハイフンを除く半角数字)
                                <br /> &nbsp;&nbsp;9. 従業員ID(半角数字)
                                <br />
                                10. 勤務タイプ(hall/kitchen/both)
                                <br />
                                11. ランク(A/B/T)
                                <br />
                                12. 雇用形態(part-time/full-time/foreigner)
                                <br />
                                13. マネジャー(YES/NO)
                                <br />
                                14. 開閉担当(YES/NO)
                                <br />
                                15. 初期パスワード(8文字以上•大文字•数字を含む)
                            </Typography>
                        </div>
                    </div>
                    <div className="shrinkable">
                        <Typography variant="subtitle1" margin="1rem">
                            新規会員の登録はこちらから
                        </Typography>
                        <Button
                            onClick={e => {
                                e.preventDefault()
                                history.push(PATHS.signup)
                            }}
                            variant="contained"
                            color="paloBlue"
                            sx={{
                                marginLeft: '1rem',
                                marginRight: '1rem',
                                marginBottom: '1.5rem',
                                height: '42px',
                                width: 'calc(100% - 2rem)'
                            }} // weird width is because 100% and fullWidth make it go off the page
                        >
                            新規会員登録
                        </Button>
                    </div>
                </div>}
            </div>
        </PageWithTitleLayout>
    )
}

export default AdminUserManagements
